* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "montserrat", sans-serif;
}

.questionpart {
  margin: 0 150px;
  padding: 30px;
  padding-bottom: 0px;
  max-width: 100%;
}

.answerpart {
  padding:40px 8vw;
  border-radius: 5px;
  box-sizing: border-box;
  max-width:100% ;
   margin: 0px 200px 50px;
   box-shadow: 4px 2px 2px rgba(0, 0, 0, 0.1) ; 
}

.h3 {
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}

.que-title {
  font-weight: 600;
  margin: 13px 3px 8px;
  font-size: 14px;
  color: #727070;
}

.description {
  font-size: 12px;
   color: #727070;
  font-weight:500;
  margin-bottom: 10px;
  margin-left: 3px;
}

.AnsFromCommunity h3 {
  margin: 10px 0 6px;
  border-bottom: 1.32px solid #d8d1d1;
  border-top: 1.32px solid #d8d1d1;
}

.communityans {
  line-height: 52px;
}

.user-ans{
  display: flex;
  justify-content: left;
  margin-bottom: 50px;
}

.my-users-icon{
   width: 50px;
   height: 40px;
  opacity: 0.4;
}
.user-icon-wrapper{
  margin-top: 20px;
}

.show-more-btn{
  cursor: pointer;
  font-weight:bold;
  color: black;
}

.answer{
font-size: 12px;
font-weight:500;
color: #2b2b2b;
margin:30px 18px 0px ;
}


.the-username{
color: #626161;
font-size: 12px;
font-weight: bold;
margin-left:7px;
margin-bottom: px;
}

.answertop {
  text-align: center;
  margin-top: 30px;
}

.goto {
  text-align: center;
  margin-top: 23px;
  font-size: 12px;
  color: #716e6e;
}

.goto:hover {
  color: rgb(254, 132, 2);
  cursor: pointer;
}

.textarea {
  max-width:100% ;
  margin-top: 30px;
  border: 1px solid #ccc;
  padding: 20px 0px ;
  padding-left: 10px; 
  resize: vertical;
  border-radius: 10px;
  outline: none;
  font-family: sans-serif;
  width: 100%;
  height: 10vw;
}

.textarea:focus {
  border-color: #ccc;}

.textarea::placeholder {
  font-family: "Montserrat" sans-serif;
  color: #948f8f;
}

.button2 {
    width: 150px;
  height: 34px;
  box-sizing: content-box;
  background-color: rgb(81, 108, 240);
  border-color: rgb(81, 108, 240);
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  contain-intrinsic-inline-size: 30px;
  border-radius: 4px;
  font-size: 11px;
  margin-top: 10px;
}

.button2:hover {
  background-color: rgb(254, 132, 2);
}

@media (max-width:1100px){

.questionpart{
margin: 0 130px;
}

.answerpart{
margin: 0 130px;
}

.button2{
  width: 15vw;
}
}

@media (max-width:800px){
.questionpart{
margin: 0 50px;
padding-bottom: 0px;
}

.answerpart{
margin: 20px 50px;
padding: 20px 4vw 20px;
}

.answertop{
  margin-top: 0px;
}

.goto{
  margin-top: 0pc;
}

.button2{
  width: 30vw;
  height: 30px;
}

.textarea{
  height: 150px;
  margin-top: 20px;
}

.h3{
  font-size: 12px;
}

}
